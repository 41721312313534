.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* API Hub Page Css */

.hero-module .hero-section-bg {
  background-image: url(./assets/images/bg.png);
  mask-image: url(./assets/images/bg-mask.svg);
  mask-position: bottom;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  mask-size: cover;
  mask-repeat: no-repeat;
}

.hero-module {
  margin-top: 140px;
  min-height: 90vh;
}

.doc-card {
  background-color: #f5f6ff !important;
}

.cursor-pointer {
  cursor: pointer;
}

.apisubcard {
  width: 24rem;
  height: 8rem;
}

@media (min-width: 768px) {
  .apisubcard {
    width: 30rem;
  }
}
