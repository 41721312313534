.wave-div {
  margin-top: 15rem;
  border-bottom: 1px solid #636363;
  position: relative;
}
.wave {
  position: absolute;
  bottom: -10px;
  z-index: -1;
}

.footer {
  background-color: #004;
}

.subscrition-div {
  border-bottom: 1px solid #636363;
  padding: 80px 0;
}

.subscription-field {
  padding: 15px;
  border-radius: 18px !important;
}

.subscription-field:focus-visible {
  outline: none;
}

.subscription-btn {
  background: #194874;
  border-color: #194874;
  color: #54f0e4;
  font-size: 16px;
  line-height: 12px;
  padding: 14px 24px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.sitemap-div {
  border-bottom: 1px solid #636363;
  padding: 80px 0;
}

.sitemap-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 35px;
}

.sitemap-list li {
  margin-bottom: 0.5rem;
}

.sitemap-list li a {
  color: #fff;
  text-decoration: none;
}

.copyright-div {
  padding: 80px 0;
}
