.navLink {
  color: #636363;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.navbar-bg {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.8) 21.6%,
    rgba(84, 240, 228, 0.8) 201.91%
  );
  backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(60px);
  transform: translate3d(0, 0, 0) !important;
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 1000;
  top: 0;
}
